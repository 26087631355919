import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link, withPrefix } from 'gatsby';
import { kebabCase } from 'lodash';
import logo from '../assets/jf-creative-logo.svg';

const config = require('../../config/SiteConfig');

const HeaderWrapper = styled.header`
  color: #fff;
  background-color: ${config.themeColor};
  height: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: flex-start;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  min-height: 64px;

  .logo-sq {
    line-height: 0;

    img {
      width: 80px;
      height: 80px;
      margin: 0;
    }
  }

  .jf-creative-logo {
    padding-left: 1.25rem;

    img {
      margin-top: 10px;
      margin-bottom: 0;
    }
  }
`;

const Header = ({ brandLogo }) => (
  <HeaderWrapper>
    {brandLogo !== null && (
      <Link className="logo-sq" to={withPrefix(`/${kebabCase(brandLogo)}`)}>
        <img alt={brandLogo} src={withPrefix(`/brands/${kebabCase(brandLogo)}-logo@2x.png`)} />
      </Link>
    )}
    <Link className="jf-creative-logo" to="/">
      <img src={logo} alt="Jellyfish Creative" />
    </Link>
  </HeaderWrapper>
);

export default Header;

Header.propTypes = {
  brandLogo: PropTypes.string,
};

Header.defaultProps = {
  brandLogo: null,
};
