import React from 'react';
import { Link } from 'gatsby';
import { kebabCase } from 'lodash';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import config from '../../config/Theme';

const SidebarWrapper = styled.aside`
  width: 320px;
  padding: 1em;
  border-right: 1px solid #ccc;
  overflow: auto;

  h3 {
    font-size: 1.2rem;
  }

  ul {
    margin: 0;

    li {
      list-style: none;
      padding: 0;
      margin: 0;
      margin-bottom: 0.25em;
      padding-bottom: 0.3em;
      border-bottom: 1px solid ${config.colors.grey.ultraLight};

      a {
        color: ${config.colors.primary};
      }

      ::after {
        content: '>';
        color: black;
        float: right;
      }
    }
  }
`;

const Sidebar = props => {
  const { links, heading } = props;

  return (
    <SidebarWrapper>
      <h3>{heading}</h3>
      <nav>
        <ul>
          {links.map(link => (
            <li key={kebabCase(link.title)}>
              <Link to={link.url}>{link.title}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </SidebarWrapper>
  );
};

Sidebar.propTypes = {
  heading: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
};

Sidebar.defaultProps = {
  heading: '',
};

export default Sidebar;
