module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"

  siteTitle: 'Jellyfish Creative | Display Gallery', // Navigation and Site Title
  siteTitleAlt: 'Jellyfish Creative | Rich Media Display Gallery', // Alternative Site title for SEO
  siteUrl: 'http://banners.jellyfishhosting.net/', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteBanner: 'social/JFTempMeta_1312x714.jpg', // Your image for og:image tag. You can find it in the /static folder
  favicon: 'src/favicon.png', // Your image for favicons. You can find it in the /src folder
  siteDescription: 'Jellyfish Creative Display Banner Portfolio', // Your site description
  author: 'JellyfishAgency', // Author for schemaORGJSONLD
  siteLogo: 'social/jf-social.png', // Image for schemaORGJSONLD

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: '@JellyfishAgency', // Twitter Username - Optional
  ogSiteName: 'Jellyfish UK', // Facebook Site Name - Optional
  ogLanguage: 'en_US', // Facebook Language

  // Manifest and Progress color
  // See: https://developers.google.com/web/fundamentals/web-app-manifest/
  themeColor: '#090939',
  backgroundColor: '#2b2e3c',

  // Settings for typography.js
  headerFontFamily: 'Source Sans Pro',
  bodyFontFamily: 'Source Sans Pro',
  baseFontSize: '20px',
};
